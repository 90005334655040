import React, {FC, useEffect, useState} from "react";
import {observer} from "mobx-react";
import {useHistory, RouteComponentProps} from "react-router-dom";

import {useStores} from "../../store/useStore";
import {ROUTES} from "../../util/routes";

import LoginForm from "./LoginForm";
import Loader from "../shared/loader";

const Login: FC<RouteComponentProps> = observer(({location}) => {
  const [loading, setLoading] = useState<boolean>(true);

  const history = useHistory();
  const {
    userStore: {
      phone,
      code,
      error,
      userStatus,
      verifyCodeAsync,
      registerAsync,
      loginProgress,
      stateUser
    }
  } = useStores();

  useEffect(() => {
    if (stateUser) {
      setLoading(false);
      const {pathname, search} = location;
      const welcome = search.includes('welcome-offer');
      const no = pathname.includes('no');
      const unsubscribe = pathname.includes('unsubscribe');
      const delAcc = pathname.includes('delete');
      const rootRoute = userStatus === 'new' ?
        `${ROUTES.ROOT}${ROUTES.PAYMENT}${no || unsubscribe ? '/no' : ''}${delAcc ? '/delete' : ''}` :
        `${ROUTES.ROOT}${no || unsubscribe ? 'no' : ''}${delAcc ? 'delete' : ''}`;
      const root = welcome ? ROUTES.SUBSCRIPTION : rootRoute;
      history.push({
        pathname: location.state === ROUTES.REMITLY ? `${ROUTES.ROOT}${ROUTES.REMITLY}` : root,
        search: location.search.toString()
      })
    }
    if (location?.state === 'redirect') {
      setLoading(false);
    }
    if (location.search.includes('redirect_uri')) {
      setLoading(false)
    }
    setLoading(false)
  }, [stateUser])

  const handleSubmitLoginForm = (phone: string, code: string | undefined, token: string | undefined) => {
    if (code) {
      verifyCodeAsync(phone, code);
    } else {
      if (phone && token) {
        phone = phone.replace(/\D/g, "");
        registerAsync(phone, token);
      }
    }
  };

  return (
    <>
      {loading ? (
        <Loader/>
      ) : (
        <LoginForm
          onSubmit={handleSubmitLoginForm}
          inProgress={loginProgress}
          phone={phone}
          code={code}
          error={error}
        />
      )}
    </>
  )
})

export default Login;
