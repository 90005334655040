import React, {useLayoutEffect, useMemo, useState} from "react";
import {useLocation} from "react-router-dom";
import {AxiosResponse} from "axios";
import {observer} from "mobx-react";

import {TActivatedSub, TSubscription} from "../../store/types";
import {useStores} from "../../store/useStore";
import {CONSTANTS, postDOMForm} from "../../util/utils";
import SubscriptionContent from "./SubscriptionContent";
import strings from "../../util/i18n/strings";

const defaultSubscription = {
  id: '',
  name: '',
  description: '',
  country: "AD",
  amount: 0,
  discountAmount: 0,
  renewAmount: 0,
  currency: '',
  vat: 0,
  volume: 0,
  remain: 0,
  validFrom: 0,
  validTo: 0,
  active: false,
  archive: false,
  autoRenew: false,
  renewToken: '',
  days: 0,
  renewDays: 0,
  isWelcomeOffer: false,
  renewVolume: 0
}

const SubscriptionSection = observer(() => {

  const {
    subscriptionStore: {
      subscriptions,
      activateSubscriptionAsync,
      getSubscriptionsAsync,
      changeStatusSubscriptionAsync
    },
    modalsStore: {
      changeStateModal,
      changeStateSnackbar
    },
    paymentsStore: {
      cards
    }
  } = useStores();

  const getLink = () => {
    switch (strings.getLanguage()) {
      case 'ru':
        return 'https://yollacalls.ru/credentials-storage-agreement-new/';
      case 'de':
        return 'https://yollacalls.de/credentials-storage-agreement-new/';
      case 'fr':
        return 'https://yollacalls.fr/credentials-storage-agreement-new/';
      default:
        return 'https://yollacalls.com/credentials-storage-agreement-new/'
    }
  }

  const link = useMemo(() => {
    return getLink()
  }, [])

  const [item, setItem] = useState<TSubscription>(defaultSubscription);
  const [items, setItems] = useState<TSubscription[]>([]);
  const {search} = useLocation();
  const {loading, list} = subscriptions;

  const [isMobile, setMobile] = useState<boolean>(false);

  useLayoutEffect(() => {
    const sub = new URLSearchParams(search);
    const offer = sub.get(CONSTANTS.WELCOME_OFFER);
    const offerSub = list.find((el) => el.isWelcomeOffer && el.country === offer);
    const activeSub = list.find((el) => el.active);
    const selectedItem = list.find((el) => el.isWelcomeOffer);

    if (activeSub) {
      setItem(activeSub);
      setItems(list);
      return;
    }

    if (offer) {
      setItem(offerSub ? offerSub : list[0]);
      setItems(list);
      return;
    }

    setItem(selectedItem ? selectedItem : list.length ? list[0] : defaultSubscription);
    setItems(list);
  }, [list]);

  const resize = () => {
    setMobile(window.innerWidth <= 800);
  }

  useLayoutEffect(() => {
    window.addEventListener('resize', resize)
    resize();
    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [])

  const handleChange = (value: string) => {
    const findElem = items.find((el) => el.id === value);
    setItem(findElem ? findElem : defaultSubscription)
  };

  const activatedSubscription = (id: string | undefined) => {
    if (id) {
      activateSubscriptionAsync(id)
        .then((res: AxiosResponse<TActivatedSub>) => {
          const {data: {paymentRequest}} = res;
          const {method, params, url} = paymentRequest;
          if (method.toUpperCase() === "POST") {
            postDOMForm(url, params)
          } else {
            const link = document.createElement('a');
            link.href = url;
            link.click();
          }
        })
    }
  }

  const changeStatus = (status: boolean, id: string) => {
    if (!status) {
      changeStateModal(strings.subscription.modal_title, true, true, 'cancelSubscription', {status: status, id: id})
    } else {
      changeStatusSubscriptionAsync(status, id)
        .then(() => {
          getSubscriptionsAsync()
        }).catch((err) => {
        changeStateSnackbar(err)
      })
    }
  }

  return (
    <SubscriptionContent
      link={link}
      item={item}
      items={items}
      cards={cards}
      isMobile={isMobile}
      loading={loading}
      handleChange={handleChange}
      changeStatusSubscription={changeStatus}
      activateSubscription={activatedSubscription}
    />
  )
})

export default SubscriptionSection;
